import styled from "styled-components";
import { Plus } from "phosphor-react";

export const Container = styled.div`
  width: 100%;
  background-color: var(--color-gray-300);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: var(--v2-color-dropdown-right);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.header`
  justify-content: space-between;
  align-items: center;
  padding: 22px 80px;
`;

export const Tag = styled.span`
  font-size: 14px;
  background: var(--color-gray-50);
  border-radius: 30px;
  font-weight: 500;
  color: var(--color-gray-900);
  text-align: center;
  width: fit-content;
  height: fit-content;
  line-height: 1.5;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderDescription = styled.div<{ scrolled: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.scrolled ? "row" : "column")};
  align-items: ${(props) => (props.scrolled ? "center" : "")};
  gap: 16px;
  & > div {
    align-items: baseline;
  }
`;

export const Title = styled.div<{ scrolled: boolean }>`
  font-size: ${(props) => (props.scrolled ? "24px" : "40px")};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: ${(props) => (props.scrolled ? " 0.48px" : "0.8px")};
  color: var(--color-text-primary);
  transition: all 0.2s ease;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-primary);
  text-transform: capitalize;
`;

export const MainContent = styled.main`
  padding: 40px 200px 0px;

  @media (max-width: 1024px) {
    padding: 40px 40px 0px;
  }
`;

export const CardGroup = styled.div<{ padding?: string; hoverColor?: string }>`
  background-color: var(--color-white);
  border-radius: 8px;
  padding: ${(props) => props.padding || "24px"};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.hoverColor || "var(--color-white)"};
  }
`;

// export const Card = styled.section`
//   display: flex;
//   margin-bottom: 24px;
//   align-items: flex-start;
//   width: 100%;
// `;

export const Card = styled.div`
  display: flex;
  img {
    border-radius: 8px;
    height: 143.3px;
    width: 216px;
  }
`;

export const ImageTitle = styled.div`
  padding-left: 16px;
  color: var(--v2-midnight-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
`;

export const Label = styled.label`
  display: block;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.36px;
  color: #292524;
  font-style: normal;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 16px;
`;

export const Section = styled.section`
  background-color: var(--color-background-primary);
  border-radius: 8px;
`;

export const TextBlock = styled.div`
  border-radius: 11px;
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 80px;
  background-color: var(--v2-grey-50);
  border-top: 2px solid var(--v2-color-dropdown-right);
  position: sticky;
  bottom: 0;
  z-index: 1000;
`;

export const FooterText = styled.div`
  font-size: 14px;
  color: var(--v2-color-gray-dark);
  margin-right: 20px;
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 109px;
  border-radius: 9px;
  border: ${({ style }) =>
    `${style?.borderWidth || "4px"} ${style?.borderStyle || "dashed"} ${
      style?.borderColor || "#b1bfd0"
    }`};
  color: var(--v2-dark-gray);
  text-align: center;
  position: relative;
  margin-top: 10px;
  background: var(--color-white);

  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    background: var(--color-white);
    border-radius: 9px;
    z-index: -1;
  }
`;

export const AttachmentContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const AttachmentTitle = styled.div`
  display: flex;
  color: var(--v2-deep-forest-green);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.07px;
`;

export const BrowseLink = styled.a`
  color: var(--color-primary-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.07px;
  text-decoration-line: underline;
  padding-left: 5px;
  cursor: pointer;
`;

export const AttachmentDescription = styled.div<{ marginTop?: string }>`
  color: var(--v2-color-gray-dark);
  leading-trim: both;
  text-edge: cap;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.055px;
  margin-top: ${(props) => props.marginTop || "16px"};
`;

export const FileAttachmentWrapper = styled.div`
  display: flex;
  border-radius: 6px;
  border: 1px solid var(--v2-color-dropdown-right);
  padding: 10px;
  position: relative;
  margin-top: 10px;
`;

export const RemoveFileButton = styled.div`
  height: 16px;
  color: #292524;
  width: 16px;
  cursor: pointer;
`;

export const FileAttachmentContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const FileName = styled.span`
  flex: 1;
  flex-grow: 1;
  color: var(--rv-grey-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-left: 12px;
  width: 34%;
  &.clickable {
    cursor: pointer;
    text-decoration: underline;
    color: var(--v2-color-active);

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ViewFileLink = styled.span`
  color: var(--v2-color-active);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  margin-right: 12px;
  letter-spacing: -0.07px;
  cursor: pointer;
  text-decoration: underline;
`;

export const FileThumbnail = styled.div`
  padding: 5px;
  background: var(--v2-light-gray);
  width: 25px;
  height: 25px;
  border-radius: 6px;
`;

export const UploadProgressWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Progress = styled.div`
  height: 5px;
  background-color: var(--color-primary-100);
  border-radius: 4px;
  overflow: hidden;
  width: 433px;
  flex-shrink: 0;
  position: relative; /* Add this to position the label absolutely */
`;

export const Filler = styled.div<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: var(--color-primary-500);
  transition: width 0.5s ease-in-out;
  border-radius: inherit;
  text-align: right;
`;

export const ProgressLabel = styled.div`
  color: var(--v2-color-gray-dark);
  font-size: 11px;
  font-weight: 500;
  line-height: 1; /* Using a number instead of percentage for simplicity */
  position: absolute;
  right: -28px; /* Keeps the label positioned correctly */
  white-space: nowrap; /* Prevents text from wrapping */
  letter-spacing: 0.33px;
  text-transform: uppercase;
`;

export const FileLink = styled.div`
  font-size: 14px;
  background: var(--v2-color-active);
  width: 30%;
  border-radius: 16px;
  color: white;
  padding-left: 15px;
  cursor: pointer;
  display: flex;
`;

export const AddAttachmentWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

export const AddAttachmentText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: var(--v2-color-active);
  margin-left: 8px;
`;

export const PlusIcon = styled(Plus)`
  color: var(--v2-color-active);
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const InputLabel = styled.label`
  color: var(--color-gray-900);
`;

export const StyledInput = styled.input`
  font-family: "ProximaNova";
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.07px;
  color: var(--color-gray-900);
  border-radius: 8px;
  padding: 16px;
  outline: none;
  margin-top: 12px;
  border: 1px solid var(--v2-color-dropdown-right);

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const AddAttachmentHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const AddAttachmentHeaderContent = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const AddAttachmentTitle = styled.h1`
  color: var(--color-gray-900);
  letter-spacing: 0.4px;
  font: 700 20px/1.1 var(--font-family-productive-display, "Proxima Nova");
`;

export const AddAttachmentDescription = styled.p`
  color: var(--v2-color-gray-dark);
  letter-spacing: -0.07px;
  margin-top: -12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const AddAttachmentRemoveButton = styled.button`
  border-radius: 50%;
  background-color: var(--v2-color-dropdown-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--v2-color-hover-gray);
  }
`;

export const ErrorText = styled.span`
  color: var(--error-color);
  font-size: 11px;
  margin-top: 12px;
  display: block;
  font-family: "ProximaNova";
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.055px;
`;
