import styled from "styled-components";

export const ContentWrapper = styled.div`
  position: relative;
  background: var(--Gray-White, #fff);
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const MessageText = styled.p`
  font-family: "ProximaNova";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: var(--color-gray-900);
  margin-top: 0px;
`;
