import { fetchOrders, ResponseData } from "../apis/orders";
import { Order } from "../interfaces/orders";
import { CellProps } from "../../components/table/Table";
import { format } from "date-fns";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { TagType } from "../../components/tag/Tag";

export const useOrders = () => {
  const { pushAlert } = useContext(AlertContext);

  const getOrders = async (userId: string, controller?: AbortController) => {
    const _orders = await fetchOrders(userId, controller);
    if (!_orders) {
      pushAlert("Failed to get patient's orders", "danger");
    }
    if (!_orders || _orders === "cancelled") return [];
    if (_orders.length > 0) {
      const orders: Order[] = _orders.map((order: ResponseData) => ({
        ...order,
        encounter: order.encounter || "",
        id: order.orderId || "",
        orderId: order.orderId || "",
        patient: order.patient || "",
        reason: order.reason || [],
        requesterId: order.requesterId || "",
        requesterName: order.requesterName || "",
        status: order.status || "",
        type: order.type || "",
      }));
      return orders;
    } else return [];
  };

  const getFriendlyDate = (unfriendlyDate: string) => {
    if (unfriendlyDate.charAt(unfriendlyDate.length - 1) === "Z") {
      unfriendlyDate = unfriendlyDate.slice(0, unfriendlyDate.length - 1);
    }
    const date = new Date(unfriendlyDate);
    return format(date, "MM/d/yyyy");
  };

  const getFriendlyStatus = (status: string) => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const getTagType = (status: string) => {
    switch (status) {
      case "active":
        return "info";
      case "draft":
        return "neutral";
      case "suspended":
        return "neutral";
      case "completed":
        return "success";
      case "cancelled":
        return "danger";
      case "revoked":
        return "danger";
      default:
        return "neutral";
    }
  };

  const getOrdersTableData = async (
    userId: string,
    handleViewResultClick: (
      id: string,
      type: string,
      tag: string,
      tagType: TagType,
      resultRequestor: string,
      resultDate: string,
      notes: string,
      orders: CellProps[][],
      resultSignedBy: string
    ) => void,
    controller?: AbortController
  ) => {
    const ordersTableData: CellProps[][] = [];
    const _orders = await getOrders(userId, controller);
    _orders.map((order) => {
      const row: CellProps[] = [];
      row.push(getFriendlyDate(order.authoredOn));
      row.push(order?.type ? order.type : "");
      row.push(order.requesterName);
      row.push({
        content: getFriendlyStatus(order.status),
        type: getTagType(order.status),
      });
      order.status === "completed"
        ? row.push({
            label: "View Order",
            onClick: () => {
              handleViewResultClick(
                order.id,
                order.type,
                getFriendlyStatus(order.status),
                getTagType(order.status),
                order.requesterName,
                getFriendlyDate(order.authoredOn),
                order.requesterName,
                [],
                order.requesterName
              );
            },
          })
        : row.push("");
      ordersTableData.push(row);
    });
    return ordersTableData;
  };

  return { getOrdersTableData };
};
