import { SecureRoute } from "@okta/okta-react";
import { NotePencil, Plus } from "phosphor-react";
import { useEffect, useMemo, useState, useContext } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Route as RouteType } from "../../components/navigationTabs/NavigationTabs";
import { Spinner } from "../../components/spinner/Spinner";
import { Header } from "../../containers/carePlan/header/Header";
import { PatientFiles } from "../patientFiles/PatientFiles";
import { Notes } from "../../containers/notes/notes/Notes";
import { RecordContent } from "../../containers/record/recordContent/RecordContent";
import { usePatient } from "../../lib/hooks/usePatient";
import { PatientInfo } from "../../lib/interfaces/user";
import { PatientMessages } from "../patientMessages/PatientMessages";
import { useVisits } from "../../lib/hooks/useVisits";
import { Visit } from "../../lib/interfaces/visits";
import { useCarePlan } from "../../lib/hooks/useCarePlan";
import { differenceInMinutes, isBefore } from "date-fns";
import { PatientSubscription } from "../patientSubscription/PatientSubscription";
import { Drawer } from "../../components/drawer/Drawer";
import { AppointmentDetail } from "../../containers/schedule/appointmentDetail/AppointmentDetail";
import { VisitsData } from "../../lib/apis/visits";
import PatientResults from "./PatientResults";
import { CarePlanPillar } from "../carePlan/CarePlanPillar";
import { PillarEdit } from "../carePlan/PillerEdit";
import { BaseContext } from "../../lib/context/context";
import { PillarCarePlan } from "../../containers/carePlan/pillarCarePlan/PillarCarePlan";
import { careplanFormatDateTime } from "../../lib/util/date";
import {
  StatusType,
  PatientDetailProps,
  PublishModalProps,
} from "../../lib/interfaces/carePlan";
import { isAuthenticationFlagEnabled } from "../../lib/util/flags";

interface PatientRouteParam {
  userId: string;
}
export interface LocationState {
  visitId: string;
  patient: PatientInfo;
  noCarePlan: boolean;
  postCall?: boolean;
}

export const Patients = () => {
  const { path } = useRouteMatch();
  return isAuthenticationFlagEnabled() ? (
    <Switch>
      <Route path={`${path}/:userId`} component={Patient} />
      <Route path={path} component={PatientResults} />
    </Switch>
  ) : (
    <Switch>
      <SecureRoute path={`${path}/:userId`} component={Patient} />
      <SecureRoute path={path} component={PatientResults} />
    </Switch>
  );
};

export const patientRoutes: RouteType[] = [
  { name: "Care Plan", value: "careplan" },
  { name: "Record", value: "record" },
  { name: "Messages", value: "messages" },
  { name: "Notes", value: "notes" },
  { name: "Files", value: "files" },
  { name: "Subscription", value: "subscription" },
];

const Patient = () => {
  const { pillarData } = useContext(BaseContext);
  const { userId } = useParams<PatientRouteParam>();
  const location = useLocation<LocationState>();
  const [primaryAction, setPrimaryAction] = useState();
  const initTab = useMemo(() => {
    const routeStack = location.pathname.split("/").filter(Boolean);
    return routeStack[routeStack.length - 1];
  }, []);
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { getPatient, getPatientDetail } = usePatient();
  const { getCarePlan } = useCarePlan();
  const { getFutureAndPastVisits } = useVisits();
  const {
    isPillarPageShow,
    setIsPillarPageShow,
    isPillarEditShow,
    setIsPillarEditShow,
  } = useContext(BaseContext);

  const [patient, setPatient] = useState<PatientInfo>();
  const [activeTab, setActiveTab] = useState<string>(initTab);
  const [noCarePlan, setNoCarePlan] = useState<boolean>();
  const [nextAppt, setNextAppointment] = useState<Visit>();
  const controller = new AbortController();
  const [apptDetailVisible, setApptDetailVisible] = useState<boolean>(false);
  const [allVisits, setAllVisits] = useState<VisitsData>();

  useEffect(() => {
    getPatient(userId).then((info) => setPatient(info));
    setIsPillarPageShow(false);
    setIsPillarEditShow(false);
  }, [userId]);

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (patient?.settings?.General?.Membership !== "Premium") {
      return;
    }
    getCarePlan(patient.id).then((carePlanRes) => {
      setNoCarePlan(carePlanRes?.id === "");
    });
  }, [patient]);

  const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  const autoShowVisitId = useMemo(() => {
    return location?.state?.visitId || "";
  }, [location]);

  const autoShowVisitPatient = useMemo(() => {
    return location?.state?.patient || "";
  }, [location]);

  const directShowAppointment = useMemo(() => {
    return autoShowVisitId && autoShowVisitPatient.id;
  }, [autoShowVisitId, autoShowVisitPatient]);

  //Check if the visit is ongoing or which will commence shortly(within 15mins)
  const verifyVisit = (visit: Visit) => {
    const now = new Date();
    const minsBeforeActivate = 15;
    const visitDateTime = new Date(visit?.dateTime.split(" ").join("T"));
    const visitDuration = visit?.duration;
    const endOfVisitDateTime = addMinutes(visitDateTime, visitDuration + 1);
    const minsFromStartOfAppt =
      visit && differenceInMinutes(visitDateTime, now);
    return (
      (minsFromStartOfAppt >= 0 && minsFromStartOfAppt <= minsBeforeActivate) ||
      (minsFromStartOfAppt < 0 && isBefore(now, endOfVisitDateTime))
    );
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    getFutureAndPastVisits(patient.id, controller).then((_visits) => {
      setAllVisits(_visits.allVisits);
      const now = new Date();
      const allVisits = [..._visits.futureVisits, ..._visits.pastVisits];
      //Get the sorted list of visits to check which visit is the closest in time
      const closestVisits = [...allVisits]?.sort((visit1, visit2) => {
        const visitDiff = new Date(visit1?.dateTime).getTime() - now.getTime();
        const visitDiffB = new Date(visit2?.dateTime).getTime() - now.getTime();
        return visitDiff - visitDiffB;
      });
      //Set the visit which is ongoing or which will commence shortly from the current time(within 15mins)
      closestVisits.forEach((visit) => {
        if (verifyVisit(visit)) {
          setNextAppointment(visit);
        }
      });
    });
  }, [patient]);

  useEffect(() => {
    if (
      activeTab === "careplan" ||
      activeTab === "record" ||
      activeTab === "subscription"
    )
      setPrimaryAction(undefined);
  }, [activeTab]);

  const handleActiveTabChange = (route: string) => {
    setActiveTab(route);
    history.replace(`${url}/${route}`);
  };

  const onDrawerClose = () => {
    setApptDetailVisible(false);
    //clear location state
    history.push(`/patient/${userId}/careplan`);
  };

  useEffect(() => {
    setApptDetailVisible(true);
  }, [location]);

  const patientDetail: PatientDetailProps = {
    id: patient?.id || "",
    name: patient?.name || "",
    assignedSex: patient?.assignedSex ?? "",
    dateOfBirth: patient?.dateOfBirth ?? "",
    mobile: patient?.mobile ?? "",
    identifiers: patient?.identifiers,
    mrnId: patient?.mrnId || "",
  };

  if (!patient) {
    return <Spinner size={96} />;
  } else if (isPillarPageShow) {
    const pillarDetail: PublishModalProps = {
      careplanId: pillarData?.contentData?.careplanId || "",
      patient: patientDetail,
      title: pillarData?.pillarName || "",
      needType: pillarData?.contentData.content.patientGroupType.name || "",
      status: pillarData?.contentData.status
        ? (pillarData?.contentData.status.replace(/^\w/, (match) =>
            match.toUpperCase()
          ) as StatusType)
        : undefined,
      updatedAt: pillarData?.contentData.updatedAt
        ? careplanFormatDateTime(
            pillarData?.contentData?.updatedAt
          )?.toLowerCase()
        : undefined,
      updatedBy: pillarData?.contentData.updatedBy
        ? pillarData?.contentData.updatedBy
        : "",
      onEditClick: () => {
        setIsPillarPageShow(false);
        setIsPillarEditShow(true);
      },
    };
    return <PillarCarePlan {...pillarDetail} />;
  } else if (!isPillarPageShow && isPillarEditShow) {
    return (
      <PillarEdit
        patient={patientDetail}
        pillarDatas={pillarData}
        onClose={() => {
          setIsPillarPageShow(true);
          setIsPillarEditShow(false);
        }}
      />
    );
  } else {
    return (
      <>
        <Header
          data-dd-privacy="mask"
          patient={patient}
          activeTab={activeTab}
          onActiveTabChange={handleActiveTabChange}
          primaryAction={primaryAction}
          primaryActionLabel={
            activeTab === "notes"
              ? "New Note"
              : activeTab === "messages"
              ? "New Message"
              : activeTab === "files"
              ? "New File"
              : undefined
          }
          primaryActionIcon={
            activeTab === "notes" || activeTab === "messages"
              ? NotePencil
              : activeTab === "files"
              ? Plus
              : undefined
          }
          allVisits={allVisits}
        />
        <Switch>
          <Route
            path={`${path}/messages`}
            render={() => (
              <PatientMessages
                setPrimaryAction={setPrimaryAction}
                patient={patient}
              />
            )}
          />
          <Route
            path={`${path}/record`}
            render={() => <RecordContent patient={patient} />}
          />
          <Route
            path={`${path}/notes`}
            render={() => (
              <Notes setPrimaryAction={setPrimaryAction} patient={patient} />
            )}
          />
          <Route
            path={`${path}/careplan`}
            render={() => <CarePlanPillar patient={patient} />}
          />

          <Route
            path={`${path}/files`}
            render={() => (
              <PatientFiles
                patient={patient}
                setPrimaryAction={setPrimaryAction}
              />
            )}
          />
          <Route
            path={`${path}/subscription`}
            render={() => <PatientSubscription patient={patient} />}
          />
        </Switch>
        {directShowAppointment && (
          <Drawer
            visible={apptDetailVisible}
            onClose={onDrawerClose}
            title="Appointment Detail"
          >
            <div style={{ overflow: "auto", maxHeight: "100%" }}>
              <AppointmentDetail
                patient={autoShowVisitPatient}
                visitId={autoShowVisitId}
                postCall={location?.state?.postCall}
              />
            </div>
          </Drawer>
        )}
      </>
    );
  }
};
