import { fetchPatientTests, Test, Comment } from "../apis/tests";
import { CellProps } from "../../components/table/Table";
import { AlertContext } from "../context/context";
import { useContext } from "react";

export const useTests = () => {
  const { pushAlert } = useContext(AlertContext);

  const getTests = async (userId: string) => {
    const _tests = await fetchPatientTests(userId);
    if (!_tests) {
      pushAlert("Failed to get patient's test results", "danger");
      return [];
    }
    return _tests;
  };

  const getResultValuesTableData = async (userId: string, orderId: string) => {
    const resultTableData: CellProps[][] = [];
    const _tests = await getTests(userId);
    _tests.map((test: Test) => {
      if (test.id === orderId) {
        test?.components &&
          test.components.map((result) => {
            const row: CellProps[] = [];
            row.push(result.name);
            row.push(result.unit);
            row.push(result.value);
            row.push(result.range);
            resultTableData.push(row);
          });
      }
    });
    return resultTableData;
  };

  const getTestResultNotes = async (userId: string, orderId: string) => {
    const _tests = await getTests(userId);
    _tests.map((test: Test) => {
      if (test.id === orderId && test?.comments) {
        return test.comments;
      }
    });
  };

  const getTestResultData = async (userId: string, orderId: string) => {
    const _tests = await getTests(userId);
    //extract notes
    let notes: Comment[] = [];
    _tests.map((test: Test) => {
      if (test.id === orderId && test?.comments) {
        if (typeof test.comments !== "string") notes = test.comments;
      }
    });
    //extract table data
    const tableData: CellProps[][] = [];
    _tests.map((test: Test) => {
      if (test.id === orderId) {
        test?.components &&
          test.components.map((result) => {
            const row: CellProps[] = [];
            row.push(result.name);
            row.push(result.unit);
            row.push(result.value);
            row.push(result.range);
            tableData.push(row);
          });
      }
    });
    return {
      tableData: tableData,
      notes: notes,
    };
  };

  return { getTests, getTestResultData };
};
