import _ from "lodash";
import { useContext, useRef } from "react";
import {
  fetchAllPatients,
  fetchAllPatientNames,
  fetchPatient,
  AllResponseData,
  fetchPatientDetails,
  updateIDVerificationStatus,
  updateInsuranceVerificationStatus,
  getInsuranceVerificationStatus,
  updateMembershipTier,
  ParamProps,
} from "../apis/patient";
import { MembershipTier, PatientInfo } from "../interfaces/user";
import { useCarePlan } from "../hooks/useCarePlan";
import { AlertContext } from "../context/context";

interface Call {
  patient: { [id: string]: Promise<any> };
  carePlan: { [id: string]: Promise<any> };
}

export const usePatient = () => {
  const { getPublishedCarePlan } = useCarePlan();
  const { pushAlert } = useContext(AlertContext);

  const calls = useRef<Call>({ patient: {}, carePlan: {} });

  const getAllPatients = async () => {
    const _patients = await fetchAllPatients();
    if (!_patients) {
      pushAlert("Failed to get all patients info", "danger");
      return [];
    }
    const patients: PatientInfo[] = _patients.map(
      (patient: AllResponseData) => {
        const formattedPatient: PatientInfo = {
          ...patient,
          firstName: patient.firstName || "Sample",
          lastName: patient.lastName || "Patient",
          name: `${patient.firstName} ${patient.lastName}`,
          photo: patient.photo,
          id: patient.userId,
          title: "Patient",
        };
        calls.current.patient[patient.userId] =
          Promise.resolve(formattedPatient);
        return formattedPatient;
      }
    );
    return patients;
  };

  const getPatient = async (id: string, newCall?: boolean) => {
    if (!calls.current.patient[id] || newCall)
      calls.current.patient[id] = fetchPatient(id);

    const patient = await calls.current.patient[id];
    if (!patient) {
      pushAlert("Failed to get patient's info", "danger");
      return {
        id: "",
        firstName: "",
        lastName: "",
        title: "",
      } as PatientInfo;
    }
    const identifiers = {
      wprinternal: _.find(
        patient.identifiers,
        (identifier) => identifier.name === "WPRINTERNAL"
      )?.value,
      epic: _.find(
        patient.identifiers,
        (identifier) => identifier.name === "EPIC"
      )?.value,
    };
    return {
      ...patient,
      identifiers,
      emergencyContact: patient.emergencyContact,
      assignedSex: patient.assignedSex,
      coverage: patient.coverage,
      dateOfBirth: patient.dateOfBirth,
      addressLine1: patient.addressLine1,
      addressLine2: patient.addressLine2,
      firstName: patient.firstName || "Sample",
      lastName: patient.lastName || "Patient",
      name: `${patient.firstName} ${patient.lastName}`,
      photo: patient.photo,
      id: patient.userId,
      title: "Patient",
      membershipTier: patient.membershipTier,
      premiumMember: Object.values(MembershipTier).includes(
        patient.membershipTier as MembershipTier
      ),
    } as PatientInfo;
  };

  const getPatientApptInfo = async (id: string, newCall?: boolean) => {
    if (!calls.current.patient[id] || newCall)
      calls.current.patient[id] = fetchPatient(id);
    const patient = await calls.current.patient[id];
    if (!patient) {
      pushAlert("Failed to fetch patient's info", "danger");
      return {
        id: "",
        firstName: "",
        lastName: "",
        title: "",
        hasCarePlan: false,
      } as PatientInfo;
    }
    const identifiers = {
      wprinternal: _.find(
        patient.identifiers,
        (identifier) => identifier.name === "WPRINTERNAL"
      )?.value,
      epic: _.find(
        patient.identifiers,
        (identifier) => identifier.name === "EPIC"
      )?.value,
    };

    if (!calls.current.carePlan[patient.userId] || newCall)
      calls.current.carePlan[patient.userId] = getPublishedCarePlan(
        patient.userId
      );
    const carePlanRes = await calls.current.carePlan[patient.userId];

    return {
      ...patient,
      identifiers,
      emergencyContact: patient.emergencyContact,
      assignedSex: patient.assignedSex,
      coverage: patient.coverage,
      dateOfBirth: patient.dateOfBirth,
      addressLine1: patient.addressLine1,
      addressLine2: patient.addressLine2,
      name: `${patient.firstName} ${patient.lastName}`,
      firstName: patient.firstName || "Sample",
      lastName: patient.lastName || "Patient",
      photo: patient.photo,
      id: patient.userId,
      title: "Patient",
      hasCarePlan: carePlanRes && carePlanRes.id && carePlanRes.id !== "",
    } as PatientInfo;
  };

  const getPatientDetails = async (id: string) => {
    return await fetchPatientDetails(id);
  };

  const updateIDstatus = async (userId: string, status: object) => {
    const IDstatus = await updateIDVerificationStatus(userId, status);
    if (!IDstatus) {
      pushAlert(
        "Failed to update Identification verification status",
        "danger"
      );
      return;
    }
    return IDstatus;
  };

  const updateInsurancestatus = async (userId: string, status: object) => {
    const insurancestatus = await updateInsuranceVerificationStatus(
      userId,
      status
    );
    if (!insurancestatus) {
      pushAlert("Failed to update Insurance validation status", "danger");
      return;
    }
    return insurancestatus;
  };

  const getInsurancestatus = async (userId: string) => {
    return await getInsuranceVerificationStatus(userId);
  };

  const getAllPatientNames = async (
    { searchText, pageNumber, pageSize, membershipTier }: ParamProps,
    signal?: AbortSignal
  ) => {
    return fetchAllPatientNames(
      {
        searchText,
        pageNumber,
        pageSize,
        membershipTier,
      },
      signal
    );
  };

  const updateMembershipTierStatus = async (
    userId: string,
    membershipTier: string
  ) => {
    const membershipTierValue = await updateMembershipTier(
      userId,
      membershipTier
    );
    if (!membershipTierValue) {
      pushAlert("Failed to update membership tier status", "danger");
      return;
    }
    return membershipTierValue;
  };

  const getPatientDetail = (arg: PatientInfo) => {
    return {
      id: arg?.id,
      firstName: arg?.firstName,
      lastName: arg?.lastName,
      title: "Patient",
      name: arg?.name,
    };
  };

  return {
    getAllPatients,
    getPatient,
    getPatientApptInfo,
    getPatientDetails,
    updateIDstatus,
    updateInsurancestatus,
    getInsurancestatus,
    getAllPatientNames,
    getPatientDetail,
    updateMembershipTierStatus,
  };
};
