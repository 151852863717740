import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  ChatPreview,
  FilterOptions,
  Message,
} from "../../../lib/interfaces/messaging";
import { ChatList } from "../chatList/ChatList";
import { Option } from "../../../lib/interfaces/input";
import { ChatListHeader } from "../chatListHeader/ChatListHeader";
import { Modal } from "../../../components/modal/Modal";
import { FiltersForm } from "../filtersForm/FiltersForm";
import { IncludePatientForm } from "../newChatForm/includePatientForm/IncludePatientForm";
import { PatientSelectForm } from "../newChatForm/patientSelectForm/PatientSelectForm";
import { ProviderSelectForm } from "../newChatForm/providerSelectForm/ProviderSelectForm";
import { SelectedChat } from "../../../pages/messages/Messages";
import styles from "./style.module.css";
import { useLocation } from "react-router-dom";
import { useChats } from "../../../lib/hooks/useChats";
import { AlertContext, BaseContext } from "../../../lib/context/context";
import _ from "lodash";

interface SidePanelProps {
  onChatSelect: (chat: SelectedChat) => void;
  newMessage?: Message;
  resetSelectedMessage: () => void;
}

interface SidePanelState {
  selectedChatId?: string;
  newChat?: boolean;
}

export const SidePanel = ({
  onChatSelect,
  newMessage,
  resetSelectedMessage,
}: SidePanelProps) => {
  const location = useLocation<SidePanelState>();
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    patients: [],
    providers: [],
    timeRange: {},
  });
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [showNewChatModal, setShowNewChatModal] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<SelectedChat>();
  const [selectedUserOptions, setSelectedUserOptions] = useState<Option[]>([]);
  const { getChatById } = useChats();
  const { pushAlert } = useContext(AlertContext);
  const { userInfo, twilio } = useContext(BaseContext);
  const chatListActionRef = useRef<any>(null);
  const refreshChatListActionRef = useRef<() => void | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshClick, setRefreshClick] = useState(false);

  useEffect(() => {
    if (userInfo)
      setSelectedUserOptions([
        {
          id: userInfo.id,
          name: userInfo.name,
          value: userInfo.id,
          avatarUrl: userInfo?.photoUrl || "",
        },
      ]);
  }, [userInfo]);

  useEffect(() => {
    if (location.state?.selectedChatId) {
      getChatById(location.state.selectedChatId).then((chat) => {
        if (chat) {
          setSelectedChat({
            id: chat.id,
            participants: chat.participants,
            regarding: chat.regarding,
          });
          if (location.state.newChat) {
            handleCreatedNewChat(chat);
          }
          window.history.replaceState({}, document.title);
        }
      });
    }
  }, [twilio, location.state]);

  useEffect(() => {
    if (selectedChat) onChatSelect(selectedChat);
  }, [selectedChat]);

  const numFilters = useMemo(() => {
    let count = 0;
    if (filterOptions.patients.length > 0) count++;
    if (filterOptions.providers.length > 0 || selectedUserOptions.length > 0)
      count++;
    if (!_.isEmpty(filterOptions.timeRange)) count++;
    return count;
  }, [filterOptions, selectedUserOptions]);

  const handleNewChatClick = () => setShowNewChatModal(true);

  const handleChatSelect = (chat: ChatPreview) => {
    setSelectedChat({
      id: chat.id,
      participants: chat.participants,
      regarding: chat.regarding,
    });
  };

  const handleFiltersApply = (
    selectedFilterOptions: FilterOptions,
    selectedUserOption?: Option
  ) => {
    resetSelectedMessage();
    if (selectedFilterOptions.timeRange?.from === "")
      delete selectedFilterOptions.timeRange.from;
    if (selectedFilterOptions.timeRange?.to === "")
      delete selectedFilterOptions.timeRange.to;
    setFilterOptions(selectedFilterOptions);
    selectedUserOption &&
      selectedUserOptions[0] !== selectedUserOption &&
      setSelectedUserOptions([selectedUserOption]);
    setShowFiltersModal(false);
  };

  const handleClearAll = () => {
    resetSelectedMessage();
    setShowFiltersModal(false);
    setFilterOptions({ patients: [], providers: [], timeRange: {} });
    userInfo &&
      setSelectedUserOptions([
        {
          name: userInfo.name,
          value: userInfo.id,
          avatarUrl: userInfo?.photoUrl || "",
        },
      ]);
  };

  const handleCreatedNewChat = (chat: ChatPreview | undefined) => {
    if (chat) {
      chatListActionRef.current(chat);
      setSelectedChat({
        id: chat.id,
        participants: chat.participants,
        regarding: chat.regarding,
      });
      if (userInfo) {
        setSelectedUserOptions([
          {
            id: userInfo.id,
            name: userInfo.name,
            value: userInfo.id,
            avatarUrl: userInfo?.photoUrl || "",
          },
        ]);
      }
    } else pushAlert("Could not create new chat", "danger");
  };

  return (
    <div className={styles.chatListContainer}>
      <ChatListHeader
        onNewChatButtonClick={handleNewChatClick}
        onFiltersButtonClick={() => setShowFiltersModal(true)}
        numFilters={numFilters}
        refreshChatList={refreshChatListActionRef}
        isLoading={isFetching}
        handleRefreshClick={setRefreshClick}
      />
      <ChatList
        actionRef={chatListActionRef}
        refreshActionRef={refreshChatListActionRef}
        filterOptions={filterOptions}
        selectedChatId={selectedChat?.id}
        onChatSelect={handleChatSelect}
        selectedUserOptions={selectedUserOptions}
        truncatePreviewOneLine={true}
        newMessage={newMessage}
        handleLoading={setIsFetching}
        isRefreshClick={isRefreshClick}
        handleRefreshClick={setRefreshClick}
      />

      <Modal
        visible={showFiltersModal}
        onCloseModal={() => setShowFiltersModal(false)}
        title="Filters"
      >
        <FiltersForm
          filterOptions={filterOptions}
          onClearAll={handleClearAll}
          onApply={handleFiltersApply}
          selectedUserOptions={selectedUserOptions}
        />
      </Modal>

      <Modal
        visible={showNewChatModal}
        onCloseModal={() => {
          setShowNewChatModal(false);
        }}
        title="Modal"
        initialRoute="include-patient"
        componentMap={{
          "include-patient": {
            title: "New Message",
            route: "include-patient",
            component: IncludePatientForm,
          },
          "patient-select": {
            title: "Select Patient",
            route: "patient-select",
            component: PatientSelectForm,
          },
          "provider-select": {
            title: "Select Provider(s)",
            route: "provider-select",
            component: ProviderSelectForm,
            props: {
              onChatCreated: handleCreatedNewChat,
            },
          },
        }}
      />
    </div>
  );
};
