import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Spinner } from "../../components/spinner/Spinner";
import { Button } from "../../components/button/Button";
import image from "../../lib/images/logo.png";

export default function LoginRedirect() {
  const { token, user, loading, signIn, authError } = useContext(AuthContext);

  return loading ? (
    <Spinner />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      {authError ? (
        <p style={{ color: "red" }}>{authError}</p>
      ) : (
        <>
          <img
            style={{ marginBottom: "30px", width: "180px" }}
            src={image}
            alt="Hoag Compass Logo"
          ></img>
          <Button onClick={signIn} label="Sign In with Microsoft Entra" />
        </>
      )}
    </div>
  );
}
