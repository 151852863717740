import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import ddConfig from "./config/datadog.config";
import "./config/tracing.ts";
import oktaConfig from "./config/okta.config";
import Login from "./pages/login/Login";
import { VideoCall } from "./pages/videoChat/VideoCall";
import { Base } from "./containers/_base/Base";
import LoginRedirect from "./containers/login/LoginRedirect";
import { isAuthenticationFlagEnabled } from "./lib/util/flags";
import { AppContextProvider } from "./lib/context/appContext";
import { AuthContextProvider } from "./contexts/AuthContext";

const env = process.env.REACT_APP_DD_ENV;
const isTracingEnabled = !(env === "local" || env === "dev");

if (isTracingEnabled) {
  // initialize DataDog RUM && Session Replay
  datadogRum.init(ddConfig.rum);
  // initialize DataDog Logging
  datadogLogs.init(ddConfig.logs);
}

export default function App() {
  const location = useLocation();
  const [authError, setAuthError] = useState<string | null>(null);

  const queryClient = new QueryClient();
  const oktaAuth = new OktaAuth(oktaConfig.auth);
  const history = useHistory();

  const onAuthRequired = () => history.push("/login");

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorDescription = params.get("error_description");
    if (errorDescription) {
      setAuthError(decodeURIComponent(errorDescription));
    }
  }, [location]);

  const isOktaEnabled = isAuthenticationFlagEnabled();

  return !isAuthenticationFlagEnabled() ? (
    <QueryClientProvider client={queryClient}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={restoreOriginalUri}
      >
        <AppContextProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/login/callback" component={LoginCallback} />
            <SecureRoute path="/test">here</SecureRoute>
            <SecureRoute
              path={`/video-chat/:visitId/:userId`}
              component={VideoCall}
            />
            <SecureRoute path="/" component={Base} />
          </Switch>
        </AppContextProvider>
      </Security>
    </QueryClientProvider>
  ) : (
    <AuthContextProvider authError={authError}>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/login" component={LoginRedirect} />
          <Route path="/test">here</Route>
          <Route path={`/video-chat/:visitId/:userId`} component={VideoCall} />
          <Route path="/" component={Base} />
        </Switch>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}
