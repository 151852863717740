import { useContext } from "react";
import { NotificationInterface } from "../interfaces/notifications";
import { fetchAllProviderNotifs, fetchAllNotifs } from "../apis/notifications";
import { AlertContext, BaseContext } from "../context/context";
import { UserInfo } from "../interfaces/user";

export const useNotifications = () => {
  const { pushAlert } = useContext(AlertContext);
  const { allProviders } = useContext(BaseContext);

  const rearrangeNotifs = (notificationsArray: NotificationInterface[]) => {
    const notifs: NotificationInterface[] = JSON.parse(
      JSON.stringify(notificationsArray)
    );
    const getCareNowNotifs: NotificationInterface[] = [];
    const filteredNotifs = notifs.filter((notif: NotificationInterface) => {
      if (notif.name === "getCareNow") {
        getCareNowNotifs.push(notif);
      }
      return notif.name !== "getCareNow";
    });
    return getCareNowNotifs.concat(filteredNotifs);
  };

  const getProviderNotifs = async (
    providerId: string,
    minDate: Date,
    controller?: AbortController
  ) => {
    const _notifs = await fetchAllProviderNotifs(
      providerId,
      minDate,
      controller
    );
    if (!_notifs) {
      pushAlert("Failed to get providers' notifications", "danger");
    }
    if (!_notifs || _notifs === "cancelled") return [];
    if (_notifs.length > 0) {
      let updNotifs: NotificationInterface[] = JSON.parse(
        JSON.stringify(_notifs)
      );
      updNotifs = updNotifs.map((notif: NotificationInterface) => {
        let creator: UserInfo = {
          id: "",
          name: "",
          firstName: "",
          lastName: "",
          photo: "",
          title: "",
        };
        if (notif.creatorType === "provider") {
          const provider = allProviders?.find(
            (provider) => provider.id === notif.creatorId
          );
          creator = {
            id: provider?.id,
            name: provider?.name,
            firstName: provider?.firstName,
            lastName: provider?.lastName,
            photo: provider?.photo,
            title: provider?.title,
          } as UserInfo;
        } else {
          creator = {
            id: notif?.subject?.userId,
            name: `${notif?.subject?.firstName} ${notif?.subject?.lastName}`,
            firstName: notif?.subject?.firstName,
            lastName: notif?.subject?.lastName,
            photo: notif?.subject?.photo ?? "",
            title: "Patient",
          } as UserInfo;
        }
        return { ...notif, creator: creator };
      });
      //put the Get Care Now notifications at the top of the list
      return rearrangeNotifs(updNotifs);
    } else return [];
  };

  const getAllNotifs = async (minDate: Date, controller?: AbortController) => {
    const _notifs = await fetchAllNotifs(minDate, controller);
    if (!_notifs) {
      pushAlert("Failed to get notifications", "danger");
    }
    if (!_notifs || _notifs === "cancelled") return [];
    if (_notifs.length > 0) {
      let updNotifs: NotificationInterface[] = JSON.parse(
        JSON.stringify(_notifs)
      );
      updNotifs = updNotifs.map((notif: NotificationInterface) => {
        let creator: UserInfo = {
          id: "",
          name: "",
          firstName: "",
          lastName: "",
          photo: "",
          title: "",
        };
        if (notif.creatorType === "provider") {
          const provider = allProviders?.find(
            (provider) => provider.id === notif.creatorId
          );
          creator = {
            id: provider?.id,
            name: provider?.name,
            firstName: provider?.firstName,
            lastName: provider?.lastName,
            photo: provider?.photo,
            title: provider?.title,
          } as UserInfo;
        } else {
          creator = {
            id: notif?.subject?.userId,
            name: `${notif?.subject?.firstName} ${notif?.subject?.lastName}`,
            firstName: notif?.subject?.firstName,
            lastName: notif?.subject?.lastName,
            photo: notif?.subject?.photo ?? "",
            title: "Patient",
          } as UserInfo;
        }
        return {
          ...notif,
          creator: creator,
          createdOn: notif.createdOn.split(" ").join("T") || "",
        };
      });
      return rearrangeNotifs(updNotifs);
    } else return [];
  };

  return { getProviderNotifs, getAllNotifs };
};
