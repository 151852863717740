import { RumInitConfiguration } from "@datadog/browser-rum";
import { LogsInitConfiguration } from "@datadog/browser-logs";

const ddConfig = {
  rum: {
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: process.env.REACT_APP_DD_SITE,
    service: process.env.REACT_APP_DD_SERVICE,
    env: process.env.REACT_APP_DD_ENV!,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    actionNameAttribute: "data-dd-action-name",
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    forwardErrorsToLogs: true,
  } as RumInitConfiguration,
  logs: {
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    service: process.env.REACT_APP_DD_SERVICE,
    env: process.env.REACT_APP_DD_ENV,
    site: process.env.REACT_APP_DD_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  } as LogsInitConfiguration,
};

export default ddConfig;
