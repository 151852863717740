import {
  useContext,
  useEffect,
  useRef,
  useState,
  ReactChild,
  MutableRefObject,
} from "react";
import { ChatPreview } from "../../../components/chatPreview/ChatPreview";
import { UserInfo } from "../../../lib/interfaces/user";
import { Option } from "../../../lib/interfaces/input";
import {
  ChatPreview as ChatPreviewType,
  FilterOptions,
  Participant,
  Message,
} from "../../../lib/interfaces/messaging";
import {
  format,
  isToday,
  isYesterday,
  isWithinInterval,
  startOfToday,
  subDays,
} from "date-fns";
import { Spinner } from "../../../components/spinner/Spinner";
import { BaseContext, AlertContext } from "../../../lib/context/context";
import { ChatsResponse, useChats } from "../../../lib/hooks/useChats";
import { Conversation } from "@twilio/conversations";
import _ from "lodash";
import clsx from "clsx";
import styles from "./style.module.css";
import { GetUserChatsArgs } from "../../../lib/apis/chat";

export interface ChatListProps {
  filterOptions: FilterOptions;
  selectedChatIndex?: number;
  selectedChatId?: string;
  onChatSelect: (chat: ChatPreviewType) => void;
  emptyStateComponent?: ReactChild;
  truncatePreviewOneLine?: boolean;
  onNoChats?: (chats: boolean) => void;
  actionRef?: MutableRefObject<any>;
  refreshActionRef?: MutableRefObject<any>;
  selectedUserOptions: Option[];
  patientMessages?: boolean;
  newMessage?: Message;
  handleLoading?: (data: boolean) => void;
  isRefreshClick?: boolean;
  handleRefreshClick?: (data: boolean) => void;
}

interface chatsRef {
  [id: string]: ChatPreviewType;
}

const PAGE_SIZE = 50;

export const ChatList = ({
  actionRef,
  refreshActionRef,
  filterOptions,
  selectedChatId,
  onChatSelect,
  emptyStateComponent,
  truncatePreviewOneLine,
  onNoChats,
  selectedUserOptions,
  patientMessages,
  newMessage,
  handleLoading,
  isRefreshClick,
  handleRefreshClick,
}: ChatListProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [chats, setChats] = useState<ChatPreviewType[]>([]);
  const { userInfo, allProviders } = useContext(BaseContext);
  const { pushAlert } = useContext(AlertContext);
  const {
    twilio,
    useUserChatPerPageData,
    formatTwilioConversation,
    getConversationById,
    formatTwilioMessage,
  } = useChats();
  const chatsRef = useRef<chatsRef>({});
  const selectedUsersRef = useRef<Option[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>();
  const bufferChats = useRef<ChatPreviewType[]>([]);
  const controller = new AbortController();
  const chatListElRef = useRef<HTMLDivElement>(null);
  const [arg, setArg] = useState<GetUserChatsArgs | undefined>(undefined);
  const [selectedChat, setSelectedChat] = useState<ChatPreviewType>();

  const { data: chatData, refetch, isFetching } = useUserChatPerPageData(arg!);

  useEffect(() => {
    if (chatData) processChatsResult(chatData);
  }, [chatData, arg]);

  useEffect(() => {
    if (handleLoading) handleLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (selectedChat) {
      handleChatSelect(selectedChat);
    }
    if (refreshActionRef)
      refreshActionRef.current = {
        refetch,
      };
  }, [selectedChat]);

  useEffect(() => {
    if (actionRef) actionRef.current = addNewChatPreview;
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (allProviders && selectedUserOptions.length > 0) {
      selectedUsersRef.current = selectedUserOptions.map((user) => ({
        providerId: user.value,
        ...user,
      }));
      setSelectedUsers(selectedUsersRef.current);
      setCurrentPage(1);
      setNextPage(null);
      setNextPageToken(null);
    }
  }, [allProviders, selectedUserOptions]);

  useEffect(() => {
    if (selectedUsers) updateChatPreview();
  }, [selectedUsers, currentPage]);

  useEffect(() => {
    refetch();
  }, [newMessage, arg]);

  useEffect(() => {
    if (twilio) {
      twilio.on("conversationAdded", (conversation) => {
        addChatPreview(conversation);
      });
      if (allProviders) {
        twilio.on("conversationUpdated", () => {
          refetch();
        });
      }
    }
  }, [twilio, allProviders]);

  useEffect(() => {
    chats &&
      chats
        .filter(filterByPatient)
        .filter(
          patientMessages
            ? filterByProvider
            : () => {
                return true;
              }
        )
        .filter(filterByTimeRange).length > 0 &&
      onNoChats &&
      onNoChats(false);
  }, [chats]);

  const fetchChatsPage = async (loading: boolean) => {
    setLoading(loading);
    await Promise.all(
      selectedUsersRef.current.map(async (user) => {
        if (!user.value) {
          pushAlert(
            "You are unable to see messages because you need your account configured in Contentful",
            "danger"
          );
          return;
        }
        const getUserChatsArgs: GetUserChatsArgs = buildGetUserChatsArgs(
          user.value
        );
        setArg(getUserChatsArgs);
      })
    );
  };

  const processChatsResult = (result: ChatsResponse | null) => {
    if (result) {
      const { chats, currentPage, nextPageToken, nextPage, totalPages } =
        result;

      if (currentPage === 1) {
        bufferChats.current = [];
      }

      //filter out any duplicate chats that users may share
      const filteredChats = chats?.filter(
        (chat) => !bufferChats.current.includes(chat)
      );
      filteredChats?.forEach(
        async (chat) => (chatsRef.current[chat.id] = chat)
      );
      bufferChats.current = bufferChats.current.concat(filteredChats);
      setNextPage(nextPage);
      setNextPageToken(nextPageToken);
      setTotalPages(totalPages);
      setChats(bufferChats.current);
      if (selectedChat && chats?.length > 0 && isRefreshClick) {
        const selectedChatLatestData = chats?.find(
          (data) => data?.id === selectedChat?.id
        );
        setSelectedChat(selectedChatLatestData);
        if (handleRefreshClick) handleRefreshClick(false);
      }
    }
    setLoading(false);
  };

  const loadNextChatsPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const updateChatPreview = async () => {
    fetchChatsPage(true);
  };

  const addNewChatPreview = async (chat: any) => {
    if (chat) {
      chatsRef.current[chat.id] = chat;
      setChats(Object.values(chatsRef.current));
    }
  };

  const addChatPreview = async (conversation: Conversation) => {
    if (!loading) {
      const chat = await formatTwilioConversation(conversation);
      chatsRef.current[chat.id] = chat;
      setChats(Object.values(chatsRef.current));
    }
  };

  const getParticipants = (chat: ChatPreviewType): UserInfo[] => {
    const participants = chat.participants.map((participant) => {
      const { firstName, lastName, photo, title, id } = participant;
      const name = [firstName, lastName].join(" ");
      return { name, firstName, lastName, title, id, photo };
    });
    return participants;
  };

  const getLatestChatTime = (chat: ChatPreviewType): string => {
    const date = new Date(chat.latestMessage?.createdDate || chat.updatedDate);

    if (isToday(date)) return format(date, "h:mm a");
    else if (isYesterday(date)) return "Yesterday";
    else if (
      isWithinInterval(date, {
        start: subDays(startOfToday(), 6),
        end: startOfToday(),
      })
    )
      return format(date, "eeee");
    else return format(date, "M/d/yy");
  };

  const filterByPatient = (chat: ChatPreviewType): boolean => {
    return (
      chat.participants.filter((participant) => {
        if (filterOptions.patients.length > 0)
          return (
            filterOptions.patients.filter(
              (patient) => patient.value === participant.id
            ).length > 0 ||
            filterOptions.patients.filter(
              (patient) => patient.value === chat.regarding?.id
            ).length > 0
          );
        else return true;
      }).length > 0
    );
  };

  const filterByProvider = (chat: ChatPreviewType): boolean => {
    return (
      chat.participants.filter((participant) => {
        if (filterOptions.providers.length > 0)
          return (
            filterOptions.providers.filter(
              (provider) => provider.value === participant.id
            ).length > 0
          );
        else return true;
      }).length > 0
    );
  };

  const filterByTimeRange = (chat: ChatPreviewType): boolean => {
    const from = filterOptions.timeRange.from
      ? new Date(filterOptions.timeRange.from)
      : undefined;
    const to = filterOptions.timeRange.to
      ? new Date(filterOptions.timeRange.to)
      : undefined;
    const updatedDate = chat.latestMessage?.createdDate
      ? new Date(chat.latestMessage?.createdDate)
      : new Date(chat.updatedDate);

    to?.setDate(to.getDate() + 1);

    if (from && to) return updatedDate >= from && updatedDate <= to;
    else if (from) return updatedDate >= from;
    else if (to) return updatedDate <= to;
    else return true;
  };

  const sortByRecentFirst = (
    chatA: ChatPreviewType,
    chatB: ChatPreviewType
  ): number => {
    const dateA = new Date(
      chatA.latestMessage?.createdDate || chatA.updatedDate
    );
    const dateB = new Date(
      chatB.latestMessage?.createdDate || chatB.updatedDate
    );
    return dateA < dateB ? 1 : -1;
  };

  useEffect(() => {
    chats?.map((chatItem) =>
      getConversationById(chatItem.id).then((chatItem) => {
        chatItem?.on("messageAdded", async (message) => {
          formatTwilioMessage(message).then(() => {
            refetch();
          });
        });
      })
    );
  }, [chats]);

  const buildGetUserChatsArgs = (userId: string) => {
    const userType =
      window.location.pathname === "/messages" ? "provider" : "patient";

    return {
      userId: userId,
      type: userType,
      controller: controller,
      paginationInfo: {
        page: nextPage || 1,
        pageSize: PAGE_SIZE,
        nextPageToken: nextPageToken || undefined,
      },
    };
  };

  function updateChatsReadHorizon(
    chats: ChatPreviewType[],
    chat: ChatPreviewType,
    userId: string | undefined
  ) {
    const newChats = [...chats];
    const chatToUpdate = {
      ...newChats?.find((c) => c.id === chat.id),
    } as ChatPreviewType;
    const chatToUpdateIndex = newChats?.findIndex((c) => c.id === chat.id);
    if (chatToUpdate.participants) {
      const newParticipants: Participant[] = [...chatToUpdate.participants];
      const participantToUpdate: Participant = {
        ...newParticipants?.find((p) => p.participantId === userId),
      } as Participant;
      const participantToUpdateIndex = newParticipants?.findIndex(
        (p) => p.participantId === userId
      );

      participantToUpdate.lastReadMessageIndex =
        chatToUpdate.latestMessage?.index || null;
      newParticipants[participantToUpdateIndex] = participantToUpdate;
      chatToUpdate.participants = newParticipants;
      newChats[chatToUpdateIndex] = chatToUpdate;
      if (bufferChats.current !== newChats) {
        bufferChats.current = newChats;
      }
      setChats(newChats);
    }
  }

  const onChatListScroll = () => {
    if (chatListElRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatListElRef.current;
      const bottomPosition = scrollHeight - clientHeight;
      const threshold = 50;
      const isAtScrollBottom = scrollTop + threshold >= bottomPosition;

      if (isAtScrollBottom && currentPage <= totalPages && !loading) {
        loadNextChatsPage();
      }
    }
  };

  const handleChatSelect = (chat: ChatPreviewType) => {
    if (selectedUsersRef.current) {
      selectedUsersRef.current.forEach((user) => {
        const userId = user.value;
        if (!userId) {
          pushAlert(
            "You are not able to mark the chat as read because there is no user identifier",
            "danger"
          );
        }
        updateChatsReadHorizon(chats, chat, userId);
      });

      const updatedChats = chats.map((c) =>
        c.id === chat.id
          ? {
              ...c,
              unread: false,
            }
          : c
      );
      setChats(updatedChats);
      onChatSelect(chat);
    }
  };

  useEffect(() => {
    if (selectedChatId) {
      const updatedChats = chats.map((chat) =>
        chat.id === selectedChatId
          ? {
              ...chat,
              unread: false,
            }
          : chat
      );
      setChats(updatedChats);
    }
  }, [selectedChatId]);

  const handleChatListSelection = (chat: ChatPreviewType) => {
    setSelectedChat(chat);
  };

  return (
    <>
      <div
        onScroll={_.debounce(onChatListScroll, 100)}
        ref={chatListElRef}
        className={clsx(
          chats &&
            chats
              .filter(filterByPatient)
              .filter(
                patientMessages
                  ? filterByProvider
                  : () => {
                      return true;
                    }
              )
              .filter(filterByTimeRange).length > 0 &&
            styles.chatList
        )}
      >
        {chats &&
          chats
            .filter(filterByPatient)
            .filter(
              patientMessages
                ? filterByProvider
                : () => {
                    return true;
                  }
            )
            .filter((chat, index, array) => {
              // Check if the current chat's ID is unique in the array
              return index === array.findIndex((item) => item.id === chat.id);
            })
            .filter(filterByTimeRange)
            .sort(sortByRecentFirst)
            .slice(0, currentPage * PAGE_SIZE)
            .map((chat) => (
              <ChatPreview
                key={chat.id}
                //if the chat is selected, then we know the user has already read the latest messages
                //this is to prevent the unread message indicator flickering
                unread={
                  selectedChatId === chat.id &&
                  chat.participants.some((p) => p.id === userInfo?.providerId)
                    ? false
                    : chat.unread
                }
                chatId={chat.id}
                onClick={() => handleChatListSelection(chat)}
                selected={selectedChatId === chat.id}
                name={chat.name}
                time={getLatestChatTime(chat)}
                participants={getParticipants(chat)}
                regarding={chat.regarding?.name}
                preview={chat.latestMessage?.body || "noLatestMessage"}
                truncatePreviewOneLine={truncatePreviewOneLine}
              />
            ))}
        {loading && <Spinner />}
      </div>
      {!loading &&
        chats &&
        chats
          .filter(filterByPatient)
          .filter(
            patientMessages
              ? filterByProvider
              : () => {
                  return true;
                }
          )
          .filter(filterByTimeRange).length === 0 &&
        emptyStateComponent}
    </>
  );
};
