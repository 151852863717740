import clsx from "clsx";
import { CircleWavyCheck } from "phosphor-react";
import { AvatarGroupCircle } from "../avatarGroupCircle/AvatarGroupCircle";
import { AvatarGroup } from "../avatarGroup/AvatarGroup";
import { Avatar } from "../avatar/Avatar";
import { PatientInfo, UserInfo } from "../../lib/interfaces/user";
import { ReactChild, useContext, useMemo } from "react";
import styles from "./style.module.css";
import { useHistory } from "react-router-dom";
import { MembershipPremium } from "../../lib/interfaces/patientInfo";
import { BaseContext } from "../../lib/context/context";

export type User = UserInfo | PatientInfo;

export type AvatarLabelGroupSize = "sm" | "md" | "lg" | "xl" | "xxl";
export interface AvatarLabelGroupProps {
  size?: AvatarLabelGroupSize;
  users: User[];
  className?: string;
  titleOverride?: ReactChild;
  userIncluded?: boolean;
  linkUser?: boolean;
  openNewTab?: boolean;
  avatarGroupGeneral?: boolean;
  onClick?: () => void;
  displayNames?: boolean;
}

export const AvatarLabelGroupChat = ({
  users,
  titleOverride,
  size = "xl",
  onClick,
  className,
  userIncluded = false,
  linkUser = false,
  openNewTab = false,
  avatarGroupGeneral = false,
  displayNames = true,
}: AvatarLabelGroupProps) => {
  const history = useHistory();
  const { user } = useContext(BaseContext);

  const allNames = useMemo(() => {
    const patientNames: any = [];
    const otherNames: any = [];

    users.forEach((participant) => {
      // If the participant's ID matches the user's ID, use 'You' instead of their name
      const name = participant.id === user?.sub ? "You" : participant.name;

      if (participant.title === "Patient" && participant.name) {
        patientNames.push(name);
      } else {
        otherNames.push(name);
      }
    });

    // Format patient names with brackets and join with other names
    const formattedPatientNames =
      patientNames.length > 0 ? `[${patientNames.join(", ")}]` : "";
    const formattedOtherNames = otherNames.join(", ");

    // Combine the formatted names
    return [formattedPatientNames, formattedOtherNames]
      .filter(Boolean)
      .join(", ");
  }, [users]);

  const displayedNames = useMemo(() => {
    const patientNames: any = [];
    const otherNames: any = [];

    users.forEach((participant) => {
      // If the participant's ID matches the user's ID, use 'You' instead of their name
      const name = participant.id === user?.sub ? "You" : participant.name;

      if (participant.title === "Patient" && participant.name) {
        patientNames.push(name);
      } else {
        otherNames.push(name);
      }
    });
    // Format patient names with brackets and join with other names
    const formattedPatientNames =
      patientNames.length > 0 ? `[${patientNames.join(", ")}]` : "";
    const formattedOtherNames = otherNames;
    // Combine the formatted names
    return [formattedPatientNames, ...formattedOtherNames].filter(Boolean);
  }, [users]);

  const displayedNamesUptoFive = displayedNames?.slice(0, 5).join(", "); // Get the first 5 names

  const userTitles = useMemo(() => {
    const patientTitle: any = [];
    const otherTitle: any = [];

    // Iterate over the filtered participants and separate the names
    users.forEach((user) => {
      if (user.title === "Patient" && user.name) {
        patientTitle.push(user.title);
      } else {
        otherTitle.push(user.title);
      }
    });

    // Format patient names with brackets and join with other names
    const formattedPatientTitles =
      patientTitle.length > 0 ? `[${patientTitle.join(", ")}]` : "";
    const formattedOtherTitles = otherTitle.join(", ");

    // Combine the formatted names
    return [formattedPatientTitles, formattedOtherTitles]
      .filter(Boolean)
      .join(", ");
  }, [users]);

  const handlePatientClick = () => {
    history.push(`patient/${users[0].id}/careplan`);
  };

  const checkIfProviderInProfile = () =>
    !window.location.href.includes("/careplan");

  return (
    <div className={clsx(styles.avatarLabelGroup, className)}>
      {users.length > 1 ? (
        <div>
          {!avatarGroupGeneral ? (
            <AvatarGroupCircle
              users={users}
              //@ts-ignore
              size={
                ["lg", "xl"].includes(size)
                  ? size
                  : ["sm", "md"].includes(size)
                  ? "lg"
                  : "xl"
              }
            />
          ) : (
            <AvatarGroup users={users} size="sm" />
          )}
        </div>
      ) : (
        <div>
          {linkUser && checkIfProviderInProfile() ? (
            openNewTab ? (
              <a
                href={"patient/" + users[0].id + "/careplan"}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.newTab}
              >
                <Avatar user={users[0]} size={size} />
              </a>
            ) : (
              <Avatar
                onClick={handlePatientClick}
                user={users[0]}
                size={size}
              />
            )
          ) : (
            <Avatar onClick={onClick} user={users[0]} size={size} />
          )}
        </div>
      )}
      {displayNames && (
        <div className={clsx(styles.infoContainer, styles[size])}>
          <div
            data-dd-privacy="mask"
            title={allNames}
            className={styles.nameContainer}
          >
            <div
              data-dd-privacy="mask"
              className={clsx(styles.names, linkUser && styles.link)}
            >
              {linkUser && checkIfProviderInProfile() ? (
                openNewTab ? (
                  <a
                    href={"patient/" + users[0].id + "/careplan"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.newTab}
                  >
                    {displayedNamesUptoFive}
                  </a>
                ) : (
                  <div data-dd-privacy="mask" onClick={handlePatientClick}>
                    {displayedNamesUptoFive}
                  </div>
                )
              ) : (
                <div data-dd-privacy="mask">{displayedNamesUptoFive}</div>
              )}
            </div>
            {users.length === 1 &&
              (users[0] as PatientInfo)?.settings?.General?.Membership ===
                MembershipPremium && (
                <CircleWavyCheck color="#1A6D93" weight="fill" />
              )}
            {displayedNames.length > 5 ? (
              <div>{[" +", displayedNames.length - 5]}</div>
            ) : null}
          </div>
          <div
            data-dd-privacy="mask"
            title={!titleOverride ? userTitles : undefined}
            className={styles.subText}
          >
            {titleOverride || userTitles}
          </div>
        </div>
      )}
    </div>
  );
};
