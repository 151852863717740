import {
  fetchAvailability,
  postWeeklyHours,
  postDateOverrides,
  deleteDateOverrides,
  Availability,
  AvailabilityPayload,
  DeletePayload,
} from "../apis/availabilityManagement";
import {
  Checked,
  WeeklyHoursArray,
  defaultChecked,
  defaultWeeklyHours,
  OverridenDate,
} from "../interfaces/availbilityManagement";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { useProvider } from "./useProvider";
import { format, isValid } from "date-fns";
import { getLocalDateTime } from "../util/date";
import { defaultProvider, ProviderInfo } from "../interfaces/user";
import { logError, logInfo, logWarn } from "../util/logger";

export interface FormattedAvailability {
  weeklyHours: WeeklyHours;
  dateOverrides: OverridenDate[];
  lastUpdate: LastUpdate;
}

interface WeeklyHours {
  checked: Checked;
  weeklyHours: WeeklyHoursArray;
}

interface LastUpdate {
  modifiedBy: ProviderInfo;
  modifiedOn: string;
}

export const useAvailabilityManagement = () => {
  const { getProvider } = useProvider();
  const { pushAlert } = useContext(AlertContext);

  const getDefaultAvailability = () => {
    const checked: Checked = JSON.parse(JSON.stringify(defaultChecked));
    const weeklyHoursArray: WeeklyHoursArray = JSON.parse(
      JSON.stringify(defaultWeeklyHours)
    );
    const weeklyHours: WeeklyHours = {
      checked: checked,
      weeklyHours: weeklyHoursArray,
    };
    const defaultAvailability: FormattedAvailability = {
      weeklyHours: weeklyHours,
      dateOverrides: [],
      lastUpdate: {
        modifiedBy: defaultProvider,
        modifiedOn: "",
      },
    };
    return defaultAvailability;
  };

  const getAvailability = async (
    userId: string,
    controller?: AbortController
  ) => {
    const _availability = await fetchAvailability(userId, controller);
    if (!_availability) {
      pushAlert("Failed to get provider's availability", "danger");
    }
    if (!_availability || _availability === "cancelled")
      return getDefaultAvailability();
    const weeklyHours: WeeklyHours = initWeeklyHours(_availability);
    const dateOverrides: OverridenDate[] = initDateOverrides(_availability);
    const lastUpdate: LastUpdate = await initLastUpdate(_availability);
    const availability: FormattedAvailability = {
      weeklyHours: weeklyHours,
      dateOverrides: dateOverrides,
      lastUpdate: lastUpdate,
    };
    return availability;
  };

  const initWeeklyHours = (res: Availability) => {
    const updatedChecked: Checked = JSON.parse(JSON.stringify(defaultChecked));
    const updatedWeeklyHours: WeeklyHoursArray = JSON.parse(
      JSON.stringify(defaultWeeklyHours)
    );
    res.availabilityMaster.availability.forEach((item) => {
      if (item.dayOfWeek !== "SUN" && item.dayOfWeek !== "SAT") {
        /*setChecked*/
        updatedChecked[item.dayOfWeek] = item.available;
        /*setWeeklyHours*/
        if (item.available) {
          updatedWeeklyHours[item.dayOfWeek] = item.times;
        } else {
          updatedWeeklyHours[item.dayOfWeek] = [
            {
              from: { name: "9:00am", value: "9:00am" },
              to: { name: "5:00pm", value: "5:00pm" },
            },
          ]; //default hours
        }
      }
    });
    return {
      checked: updatedChecked,
      weeklyHours: updatedWeeklyHours,
    };
  };

  const getWeeklyHours = async (userId: string) => {
    const _weeklyHours = await fetchAvailability(userId);
    if (!_weeklyHours) {
      pushAlert("Failed to get provider's weekly hours", "danger");
      return {
        checked: JSON.parse(JSON.stringify(defaultChecked)),
        weeklyHours: JSON.parse(JSON.stringify(defaultWeeklyHours)),
      };
    }
    const weeklyHours: WeeklyHours = initWeeklyHours(_weeklyHours);
    return weeklyHours;
  };

  const updateWeeklyHours = async (
    userId: string,
    payload: AvailabilityPayload
  ) => {
    const res = await postWeeklyHours(userId, payload);
    if (res === "error") {
      pushAlert("Failed to update provider's weekly hours", "danger");
      logWarn("useAvailabilityManagement updateWeeklyHours: ", {
        message: res,
      });
    }
  };

  const initDateOverrides = (res: Availability) => {
    let updatedDates: OverridenDate[] = [];
    res.availabilityExceptions.availability.forEach((item) => {
      let overridenDate: OverridenDate;
      if (item.times.length === 0) {
        /*unavailable*/
        overridenDate = { date: item.date };
      } else {
        overridenDate = {
          date: item.date,
          hours: item.times,
        };
      }
      updatedDates = [...updatedDates, overridenDate];
    });
    return updatedDates;
  };

  const getDateOverrides = async (userId: string) => {
    const _weeklyHours = await fetchAvailability(userId);
    if (!_weeklyHours) {
      pushAlert("Failed to get provider's date overrides", "danger");
      return [];
    }
    const dateOverrides: OverridenDate[] = initDateOverrides(_weeklyHours);
    return dateOverrides;
  };

  const updateDateOverrides = async (
    userId: string,
    payload: AvailabilityPayload
  ) => {
    const res = await postDateOverrides(userId, payload);
    if (res === "error") {
      pushAlert("Failed to update provider's date overrides", "danger");
      logWarn("useAvailabilityManagement updateDateOverrides: ", {
        message: res,
      });
    }
  };

  const removeDateOverrides = async (
    userId: string,
    payload: DeletePayload
  ) => {
    const res = await deleteDateOverrides(userId, payload);
    if (res === "error") {
      pushAlert("Failed to delete date override", "danger");
      logWarn("useAvailabilityManagement removeDateOverrides: ", {
        message: res,
      });
    }
  };

  const initLastUpdate = async (availability: Availability) => {
    const availabilityMaster = availability.availabilityMaster;
    const provider = await getProvider(availabilityMaster.modifiedBy || "");
    const date = availabilityMaster?.modifiedOn
      ? getLocalDateTime(availabilityMaster.modifiedOn)
      : new Date("");
    const modifiedOn = isValid(date)
      ? format(date, "MMMM d, yyyy") + " at " + format(date, "h:mmaaa")
      : "";

    const lastUpdate: LastUpdate = {
      modifiedBy: provider,
      modifiedOn: modifiedOn,
    };
    return lastUpdate;
  };

  const getLastUpdate = async (userId: string) => {
    const _availability: Availability = await fetchAvailability(userId);
    if (!_availability) {
      pushAlert("Failed to get last update", "danger");
      return {
        modifiedBy: {
          id: "",
          name: "",
          firstName: "",
          lastName: "",
          title: "",
          primarySpecialty: "",
        },
        modifiedOn: "",
      };
    }
    const lastUpdate: LastUpdate = await initLastUpdate(_availability);
    return lastUpdate;
  };

  return {
    getAvailability,
    getWeeklyHours,
    updateWeeklyHours,
    getDateOverrides,
    updateDateOverrides,
    removeDateOverrides,
    getLastUpdate,
  };
};
