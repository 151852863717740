import { fetchCareTeam, postCareTeam, CareTeamPayload } from "../apis/careTeam";
import { ProviderInfo } from "../interfaces/user";
import { useProvider } from "./useProvider";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { logInfo, logWarn } from "../util/logger";

export const useCareTeam = () => {
  const { getProvider } = useProvider();
  const { pushAlert } = useContext(AlertContext);

  //get a single user's care team
  const getCareTeam = async (userId: string, allProviders?: ProviderInfo[]) => {
    const _careTeam = await fetchCareTeam(userId);
    if (!_careTeam) {
      pushAlert("Failed to get patient's care team", "danger");
      return [];
    }
    if (_careTeam.length > 0) {
      if (!allProviders) {
        let careTeam: ProviderInfo[] = await Promise.all(
          _careTeam[0].providerIds.map(
            async (providerId: string): Promise<ProviderInfo> => {
              const info = await getProvider(providerId, true);
              return info;
            }
          )
        );
        careTeam = careTeam.filter((member) => member !== undefined);
        return careTeam;
      } else {
        let careTeam: ProviderInfo[] = _careTeam.map((prov: ProviderInfo) =>
          allProviders?.find((provider) => provider.id === prov.id)
        );
        careTeam = careTeam.filter((member) => member !== undefined);
        return careTeam;
      }
    } else return [];
  };

  //only get a user's care team's ids
  const getCareTeamIds = async (
    userId: string,
    controller?: AbortController
  ) => {
    const _careTeam = await fetchCareTeam(userId, controller);
    if (!_careTeam || _careTeam === "cancelled") return [];
    return _careTeam;
  };

  const updateCareTeam = async (payload: CareTeamPayload) => {
    const res = await postCareTeam(payload);
    if (res === "error") {
      pushAlert("Failed to update patient's care team", "danger");
      logWarn("useCareTeam - updateCareTeam: ", { error: res });
    }
  };

  return { getCareTeam, getCareTeamIds, updateCareTeam };
};
