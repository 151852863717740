import type { AuthConfig } from "@aws-amplify/core";

const config: AuthConfig = {
  Cognito: {
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || "",
    userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
    loginWith: {
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN || "",
        scopes: ["email", "openid", "profile"],
        redirectSignIn: [process.env.REACT_APP_COGNITO_REDIRECT_URI || ""],
        redirectSignOut: [process.env.REACT_APP_COGNITO_REDIRECT_URI || ""],
        responseType: "code",
        providers: [{ custom: "EntraOIDC" }],
      },
    },
    userAttributes: {
      preferred_username: {
        required: true,
      },
      profile: {
        required: true,
      },
      sub: {
        required: true,
      },
    },
  },
};

export default config;
