import { useContext, useEffect, useState } from "react";
import { fetchVisitReasons } from "../apis/visitReasons";
import { VisitReason, VisitType } from "../apis/types/visitReason.types";
import { GenderType } from "../apis/types/gender.types";
import { AlertContext } from "../context/context";

export const useVisitReasons = () => {
  const { pushAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [visitReasons, setVisitReasons] = useState<VisitReason[]>([]);
  const [filteredVisitReasons, setFilteredVisitReasons] = useState<
    VisitReason[]
  >([]);

  useEffect(() => {
    const fetchReasons = async () => {
      setLoading(true);
      const reasons = await fetchVisitReasons();
      if (!reasons) {
        pushAlert("Failed to get all visit reasons", "danger");
      }
      setVisitReasons(reasons);
      setLoading(false);
    };
    fetchReasons();
  }, [pushAlert]);

  const getVisitReasonByVisitType = (
    visitType: VisitType
  ): VisitReason | undefined => {
    return visitReasons.find((reason) => reason?.visitType === visitType);
  };

  const getVisitReasonsByGender = async (gender: GenderType) => {
    setFilteredVisitReasons(
      visitReasons.filter((reason) => reason.genders.includes(gender))
    );
  };

  return {
    loading,
    visitReasons,
    filteredVisitReasons,
    getVisitReasonByVisitType,
    getVisitReasonsByGender,
  };
};
