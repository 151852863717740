import { Button, ButtonGroup } from "../../button/Button";
import { ButtonWrapper, ContentWrapper, MessageText } from "./Styled";

interface ConfirmPopupProps {
  onSubmit: () => void;
  onCancel: () => void;
  message?: string;
  showButtons?: boolean;
}

export const ConfirmPopup = ({
  onSubmit,
  onCancel,
  message,
  showButtons = true,
}: ConfirmPopupProps) => {
  return (
    <ContentWrapper>
      {message && <MessageText>{message}</MessageText>}
      {showButtons && (
        <ButtonWrapper>
          <ButtonGroup>
            <Button
              onClick={onCancel}
              type="secondary"
              size="small"
              label="Keep Attachment"
            />
            <Button
              onClick={onSubmit}
              type="primary"
              size="small"
              label="Remove Attachment"
            />
          </ButtonGroup>
        </ButtonWrapper>
      )}
    </ContentWrapper>
  );
};
