import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Amplify } from "aws-amplify";
import {
  fetchAuthSession,
  JWT,
  signInWithRedirect,
  signOut as _signOut,
} from "aws-amplify/auth";
import { sessionStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import axios from "axios";
import cognitoConfig from "../config/cognito.config";
import { Modal } from "../components/modal/Modal";
import { LogoutPromptForm } from "../containers/logout/LogoutPromptForm";
import { debounce } from "lodash";

const API_URL = process.env.REACT_APP_API_URL;
const LOGOUT_PROMPT_TIMEOUT = 3 * 60 * 1000; // 3 minutes
const AUTO_LOGOUT_TIMEOUT = 2 * 60 * 1000; // 2 minutes
const AUTO_RENEW_THRESHOLD = 3 * 60 * 1000; // 3 minutes before expiry to automatically renew
const SESSION_TRACK_DELAY = 30000; // 30 seconds
const USER_ACTIVITY_RELEASE_TIME = 500; // 500 milliseconds

Amplify.configure({
  Auth: cognitoConfig,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

axios.defaults.baseURL = API_URL;

export interface AuthContextType {
  loading: boolean;
  token?: JWT | null;
  user?: CognitoUser | null;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  authError?: string | null;
}

export interface BaseUser {
  sub: string;
  email: string;
  [key: string]: any;
}

export interface CognitoUser extends BaseUser {
  "custom:okta_sub"?: string;
}

export const AuthContext = createContext<AuthContextType>({
  loading: false,
  signIn: async () => {},
  signOut: async () => {},
});

export const AuthContextProvider = ({
  children,
  authError,
}: {
  children: React.ReactNode;
  authError?: string | null;
}) => {
  const history = useHistory();
  const [token, setToken] = useState<JWT | null>(null);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const autoLogoutTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const logoutPromptTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const sessionTrackTimerRef = useRef<NodeJS.Timeout | null>(null);
  const isRenewedRef = useRef(false);

  useEffect(() => {
    const checkAuth = async () => {
      setLoading(true);
      try {
        const session = await fetchAuthSession();
        if (!session.tokens) {
          history.push("/login");
        } else {
          setToken(session.tokens?.idToken || null);
          setUser((session.tokens?.idToken?.payload as CognitoUser) || null);
        }
      } catch (error) {
        console.error("Error fetching Cognito session:", error);
        await signIn();
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const signIn = async () => {
    await signInWithRedirect({ provider: { custom: "EntraOIDC" } });
  };

  const signOut = async () => {
    await _signOut({ global: true });

    setToken(null);
    setUser(null);
    clearAllTimers();
    history.push("/");
  };

  const refreshToken = async () => {
    try {
      const session = await fetchAuthSession({ forceRefresh: true });
      const newToken = session.tokens?.idToken;
      if (newToken) {
        setToken(newToken);
        return newToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
    return null;
  };

  const handleLogoutNow = () => {
    setShowLogoutModal(false);
    signOut();
  };

  const handleStayConnected = async () => {
    setShowLogoutModal(false);
    clearTimeout(autoLogoutTimeoutRef.current!);
    clearTimeout(logoutPromptTimeoutRef.current!);
    const newToken = await refreshToken();
    if (newToken) {
      setupLogoutTimers();
    }
  };

  const checkIfInVideoChat = () => {
    const video = localStorage.getItem("video");
    const videodata = video ? JSON.parse(video) : null;

    return videodata && videodata.videoJoined;
  };

  const setupLogoutTimers = (currentToken?: JWT | null) => {
    clearAllTimers();
    if (currentToken) {
      const currentTime = Date.now();
      const tokenExpiry = (currentToken.payload.exp || 0) * 1000;

      if (!tokenExpiry) return;

      const timeUntilPrompt = tokenExpiry - currentTime - AUTO_RENEW_THRESHOLD;
      if (timeUntilPrompt > 0) {
        logoutPromptTimeoutRef.current = setTimeout(async () => {
          handleLogoutPrompt();
        }, timeUntilPrompt);
      } else {
        handleLogoutPrompt();
      }
    }
  };

  const handleLogoutPrompt = async () => {
    const isInVideoChat = checkIfInVideoChat();
    if (isInVideoChat) {
      const newToken = await refreshToken();
      setupLogoutTimers(newToken);
    } else {
      setShowLogoutModal(true);
      autoLogoutTimeoutRef.current = setTimeout(
        handleLogoutNow,
        AUTO_LOGOUT_TIMEOUT
      );
    }
  };

  const checkShouldShowLogoutPrompt = () => {
    if (!token) {
      return;
    }

    const remainingTime = remainingSessionTime();
    const isInVideoChat = checkIfInVideoChat();
    if (isInVideoChat && remainingTime <= AUTO_RENEW_THRESHOLD) {
      handleStayConnected();
    } else if (remainingTime < LOGOUT_PROMPT_TIMEOUT) {
      setShowLogoutModal(true);
      handleRenewSession();
    }
  };

  const createSessionTrackTimer = () => {
    const timer = setInterval(() => {
      checkShouldShowLogoutPrompt();
    }, SESSION_TRACK_DELAY);

    return timer;
  };

  const clearAllTimers = () => {
    if (logoutPromptTimeoutRef.current) {
      clearTimeout(logoutPromptTimeoutRef.current);
      logoutPromptTimeoutRef.current = null;
    }
    if (autoLogoutTimeoutRef.current) {
      clearTimeout(autoLogoutTimeoutRef.current);
      autoLogoutTimeoutRef.current = null;
    }
    if (sessionTrackTimerRef.current) {
      clearInterval(sessionTrackTimerRef.current);
      sessionTrackTimerRef.current = null;
    }
  };

  const remainingSessionTime = () => {
    const now = Date.now();
    const tokenExpiry = (token?.payload.exp || 0) * 1000;
    const remainingTime = Math.max(tokenExpiry - now, 0);
    return remainingTime;
  };

  const handleRenewSession = () => {
    if (logoutPromptTimeoutRef.current) {
      clearTimeout(logoutPromptTimeoutRef.current);
    }

    logoutPromptTimeoutRef.current = setTimeout(() => {
      if (!isRenewedRef.current) {
        handleLogoutNow();
      } else {
        isRenewedRef.current = false;
        return;
      }
    }, LOGOUT_PROMPT_TIMEOUT);
  };

  const handleUserActivity = async () => {
    if (token) {
      const remainingTime = remainingSessionTime();
      if (remainingTime < AUTO_RENEW_THRESHOLD) {
        const newToken = await refreshToken();
        setupLogoutTimers(newToken);
      } else {
        setupLogoutTimers();
      }
    }
  };

  const debouncedHandleUserActivity = debounce(
    handleUserActivity,
    USER_ACTIVITY_RELEASE_TIME
  );
  useEffect(() => {
    if (token) {
      const events = [
        "mousemove",
        "keypress",
        "mouseup",
        "mouseleave",
        "scroll",
      ];
      events.forEach((event) =>
        document.addEventListener(event, debouncedHandleUserActivity)
      );
      return () => {
        events.forEach((event) =>
          document.removeEventListener(event, debouncedHandleUserActivity)
        );
      };
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.toString()}`;
    }
  }, [token]);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const newToken = await refreshToken();
          if (newToken) {
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newToken.toString()}`;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    setupLogoutTimers();
  }, [token]);

  useEffect(() => {
    if (sessionTrackTimerRef.current) {
      clearInterval(sessionTrackTimerRef.current);
    }

    const timer = createSessionTrackTimer();
    sessionTrackTimerRef.current = timer;
  }, [token]);

  return (
    <AuthContext.Provider
      value={{ loading, token, user, signIn, signOut, authError }}
    >
      {children}
      <Modal
        dismissable={false}
        visible={showLogoutModal}
        onCloseModal={() => {
          setShowLogoutModal(false);
        }}
        title="Would you like to remain connected?"
      >
        <LogoutPromptForm
          onLogoutNow={handleLogoutNow}
          onStayConnected={handleStayConnected}
        />
      </Modal>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
