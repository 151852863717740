import React, { useContext, useRef } from "react";
import { Button } from "../../../components/button/Button";
import { Paperclip, X, Trash } from "phosphor-react";
import styles from "./style.module.css";

import {
  AttachmentContent,
  AttachmentDescription,
  AttachmentTitle,
  AttachmentWrapper,
  BrowseLink,
  CardGroup,
  FileAttachmentContent,
  FileAttachmentWrapper,
  FileName,
  FileThumbnail,
  Label,
  MainContent,
  RemoveFileButton,
  Section,
  InputContainer,
  InputLabel,
  StyledInput,
  AddAttachmentHeader,
  AddAttachmentHeaderContent,
  AddAttachmentDescription,
  AddAttachmentRemoveButton,
  ErrorText,
  ViewFileLink,
} from "./Styled";
import { AlertContext } from "../../../lib/context/context";

interface AddAttachmentProps {
  onRemove: () => void;
  onFileChange: (file: File | null, name: string) => void;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
  fileData?: { file: File | null; name: string };
  title: string;
  description: string;
  fieldErrors?: {
    title?: boolean | string;
    description?: boolean;
    file?: boolean;
  };
  attachmentSignedUrl?: string;
}

const ERROR_COLOR = "var(--error-color)";

export const AddAttachment: React.FC<AddAttachmentProps> = ({
  onRemove,
  onFileChange,
  onTitleChange,
  onDescriptionChange,
  fileData,
  title,
  description,
  fieldErrors,
  attachmentSignedUrl,
}) => {
  const filePickerRef = useRef<HTMLInputElement>(null);
  const { pushAlert } = useContext(AlertContext);

  const handleAddAttachmentClick = (): void => {
    if (filePickerRef.current) {
      filePickerRef.current.click();
    } else {
      pushAlert("Unable to open file picker. Please try again.", "danger");
    }
  };

  const MAX_SIZE = 100 * 1024 * 1024;

  const handleFilePick = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];

      if (file.size > MAX_SIZE) {
        pushAlert(
          `File size must be less than or equal to ${
            MAX_SIZE / (1024 * 1024)
          }MB`,
          "danger"
        );
        return;
      }

      onFileChange(file, file.name);
    } else {
      onFileChange(null, "");
    }
  };

  const handleFileNameClick = () => {
    if (attachmentSignedUrl) {
      window.open(attachmentSignedUrl, "_blank");
    }
  };

  return (
    <>
      <MainContent>
        <CardGroup>
          <Section>
            <AddAttachmentHeader>
              <AddAttachmentHeaderContent>
                <Label>Attachment</Label>
                <AddAttachmentDescription>
                  Module must be completely empty or completely filled to be
                  accepted for Exiting, Saving or Publishing.
                </AddAttachmentDescription>
              </AddAttachmentHeaderContent>
              <AddAttachmentRemoveButton
                aria-label="Remove attachment"
                onClick={onRemove}
              >
                <Trash size={20} />
              </AddAttachmentRemoveButton>
            </AddAttachmentHeader>

            <InputContainer>
              <InputLabel>Title</InputLabel>
              <StyledInput
                type="text"
                value={title}
                onChange={(e) => {
                  const newTitle = e.target.value;
                  onTitleChange(newTitle);
                }}
                style={{
                  borderColor: fieldErrors?.title ? ERROR_COLOR : undefined,
                  borderWidth: fieldErrors?.title ? "1px" : undefined,
                }}
              />
              {fieldErrors?.title && (
                <ErrorText>
                  {typeof fieldErrors.title === "string"
                    ? fieldErrors.title
                    : "Add title"}
                </ErrorText>
              )}
            </InputContainer>
            <InputContainer>
              <InputLabel>Short Description</InputLabel>
              <StyledInput
                type="text"
                value={description}
                onChange={(e) => onDescriptionChange(e.target.value)}
                style={{
                  borderColor: fieldErrors?.description
                    ? ERROR_COLOR
                    : undefined,
                  borderWidth: fieldErrors?.description ? "1px" : undefined,
                }}
              />
              {fieldErrors?.description && (
                <ErrorText>Add short description</ErrorText>
              )}
            </InputContainer>
            <InputContainer>
              <InputLabel>Upload and Attach File</InputLabel>
              {!fileData?.name ? (
                <AttachmentWrapper
                  style={{
                    borderColor: fieldErrors?.file
                      ? ERROR_COLOR
                      : "var(--v2-pale-steel-blue)",
                    borderWidth: fieldErrors?.file ? "1px" : "1px",
                    borderStyle: "dashed",
                  }}
                >
                  <div
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      const files = e.dataTransfer.files;
                      const allowedTypes = [
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/pdf",
                        "image/png",
                        "image/jpeg",
                        "image/jpg",
                      ];

                      if (files.length > 1) {
                        return;
                      }

                      const file = files[0];
                      if (!allowedTypes.includes(file.type)) {
                        return;
                      }

                      if (file.size > MAX_SIZE) {
                        pushAlert(
                          `File size must be less than or equal to ${
                            MAX_SIZE / (1024 * 1024)
                          }MB`,
                          "danger"
                        );
                        return;
                      }

                      handleFilePick(files);
                    }}
                  >
                    <AttachmentContent>
                      <input
                        ref={filePickerRef}
                        type="file"
                        accept=".doc,.docx,.pdf,.png,.jpeg,.jpg"
                        style={{ display: "none" }}
                        onChange={(e) => handleFilePick(e.target.files)}
                        multiple={false}
                      />

                      <Button
                        type="secondary-gray"
                        onClick={handleAddAttachmentClick}
                        Icon={Paperclip}
                        disabled={false}
                        className={styles.attachmentButton}
                      />
                      <AttachmentTitle>
                        Drop your file here or{" "}
                        <BrowseLink onClick={handleAddAttachmentClick}>
                          {" "}
                          browse
                        </BrowseLink>
                      </AttachmentTitle>

                      <AttachmentDescription>
                        Supported formats: DOC, PDF, PNG, JPEG
                      </AttachmentDescription>
                      <AttachmentDescription marginTop="0px">
                        Max file size: {`${MAX_SIZE / (1024 * 1024)}`} MB
                      </AttachmentDescription>
                    </AttachmentContent>
                  </div>
                </AttachmentWrapper>
              ) : (
                <FileAttachmentWrapper>
                  <FileAttachmentContent>
                    <FileThumbnail>
                      <Paperclip size="25px" />
                    </FileThumbnail>

                    <FileName
                      onClick={handleFileNameClick}
                      style={{
                        cursor: attachmentSignedUrl ? "pointer" : "default",
                      }}
                    >
                      {fileData?.name}
                    </FileName>

                    {attachmentSignedUrl && (
                      <ViewFileLink
                        onClick={handleFileNameClick}
                        style={{
                          cursor: attachmentSignedUrl ? "pointer" : "default",
                        }}
                      >
                        View Attachment
                      </ViewFileLink>
                    )}

                    <RemoveFileButton
                      onClick={() => {
                        onFileChange(null, "");
                      }}
                    >
                      <X />
                    </RemoveFileButton>
                  </FileAttachmentContent>
                </FileAttachmentWrapper>
              )}
              {fieldErrors?.file && <ErrorText>Add attachment</ErrorText>}
            </InputContainer>
          </Section>
        </CardGroup>
      </MainContent>
    </>
  );
};
